.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}
.flex {
  flex: 1;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.MuiDialog-paperWidthSm{
  min-width: 80% !important;
}

.MuiGrid-item{
  padding: 0 2%;
}

.paper {
  padding: 16px;
  margin: 24px 16px 0 16px;
}

.marginTop {
  margin-top: 16px;
}